<template>
	<div v-if="questionnaireAnswer != null">
		<div class="header">
			<h1>{{ questionnaireAnswer.questionnaire.name }}</h1>
			<p>{{ questionnaireAnswer.questionnaire.description }}</p>
			<small class="text-danger" v-if="!submitted"> * {{$t('questionnaire.required')}} </small>
		</div>


		<div class="questions" v-if="!submitted">
			<Question
				v-for="qa in questionnaireAnswer.questionAnswers"
				:key="`${updateKey}-${qa.id}`"
				:questionAnswer="qa"
				:questionnaireAnswer="questionnaireAnswer"
				:msgs="msgs"
				:readonly="submitted"
			/>

			<div class="mt-5">
				<label for="comment">{{ $t('questionnaire.comment') }}:</label>
				<InputTextArea
					class="form-control"
					id="comment"
					v-model="comment"
					:readonly="submitted"
				/>

			</div>

			<div class="mb-5 d-flex justify-content-center align-content-center">
				<button
					:disabled="submitting"
					class="btn btn-primary mt-5"
					@click="submitQuestionnaire"
				>
					<font-awesome-icon icon="paper-plane" />
					{{ $t('questionnaire.submit') }}
				</button>
			</div>
		</div>

		<div v-else class="submitted">
			<font-awesome-icon icon="check-circle" size="3x" class="text-success"/>
			<h4 >
				{{ $t('questionnaire.submitted') }}
			</h4>
		</div>

	</div>
	<div v-else>
		<LoadingSpinner/>
	</div>
</template>

<script>

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Question from "@/components/questionnaire/Question.vue";

export default {
	name: 'QuestionnaireView',
	components: { Question, LoadingSpinner },
	computed: {

	},
	data() {
		return {
			submitting: false,
			submitted: false,
			/** @type {QuestionnaireAnswer|null} */
			questionnaireAnswer: null,
			comment: "",
			updateKey: 0,
		}
	},
	watch: {
		'$i18n.locale': function() {
			console.log("locale changed");
			this.fetchQuestionnaireAnswer();
		}
	},
	props: {
		msgs: Object
	},
	methods: {

		async submitQuestionnaire() {
			this.submitting = true;
			const errors = await this.$rest.submitQuestionnaireAnswer(this.questionnaireAnswer.token, this.comment);
			if(errors === null) {
				this.submitting = false;
				// this.$emit("addMessage", {
				// 	severity: "error",
				// 	message: this.$t('questionnaire.submit_error'),
				// })
				return;
			}

			if(errors)

			this.submitted = true;
		},
		async fetchQuestionnaireAnswer() {
			this.questionnaireAnswer = await this.$rest.getQuestionnaireAnswer(this.$route.params.token);
			if (this.questionnaireAnswer === null) {
				await this.$router.replace({name: 'home'});
				return;
			}

			this.questionnaireAnswer = {
				...this.questionnaireAnswer
			}

			this.updateKey++;

			this.submitted = this.questionnaireAnswer.submittedOn != null;
		},
	},
	async beforeMount() {
		await this.fetchQuestionnaireAnswer();
	}
}

</script>

<style scoped>

.header{
	padding-bottom: 20px;
	border-bottom: lightgrey solid 1px;
}

.header h1{
	font-size: 2.5rem;
	margin-bottom: 10px;
}

.header p{
	font-size: 1rem;
}

.header small{
	font-size: 0.8rem;
	font-weight: bold;
}

.submitted {
	font-size: 1.5rem;
	text-align: center;
	margin-top: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}

@media screen and (max-width: 768px) {
	.header h1{
		font-size: 2rem;
		margin-bottom: 10px;
	}

	.header p{
		font-size: 0.8rem;
	}

	.header small{
		font-size: 0.7rem;
	}
}

</style>
